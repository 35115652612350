import "./SliderViajes.css";
import { useState, useEffect } from "react";
import { Row, Col, Carousel, Button, Form  } from "react-bootstrap";
import ReactCalendar from "react-calendar";
import { Calendar, DateObject } from "react-multi-date-picker";
import Moment from "moment";
import PrevIcon from "./src/prev-arrow.svg";
import NextIcon from "./src/next-arrow.svg";
import IdaIcon from "./src/ida-icon.svg";
import VueltaIcon from "./src/vuelta-icon.svg";
import CalendarIcon from "./src/calendar-icon.svg";
import Navegacion from "./../Navegacion/Navegacion";
import Swal from "sweetalert2";

import { useTranslation } from "react-i18next";

function SliderViajes({ clickOut, showCruce, rutas, calendarFechaIda, calendarFechaVuelta, showSelectLanguage, showLogin }) {
    const [t, i18n] = useTranslation("global");

    const [images, setImages] = useState([]);
    const [origenesIda, setOrigenesIda] = useState([]);
    const [origenesIdaVuelta, setOrigenesIdaVuelta] = useState([]);
    const [destinosIda, setDestinosIda] = useState([]);
    const [destinosIdaVuelta, setDestinosIdaVuelta] = useState([]);

    const [origen, setOrigen] = useState(0);
    const [destino, setDestino] = useState(0);
    const [cantidadPasajeros, setCantidadPasajeros] = useState(1);
    const [categoriaVehiculo, setCategoriaVehiculo] = useState(0);

    const [idaVuelta, setIdaVuelta] = useState(false);

    const [fechaIda, setFechaIda] = useState("");
    const [fechaVuelta, setFechaVuelta] = useState("");
    const [checkVehiculo, setCheckVehiculo] = useState(false);
    const [allowVehicle, setAllowVehicle] = useState(false); // Estado para manejar el disabled

    const [fechas, setFechas] = useState([
        new DateObject(),
        new DateObject()
    ])

    useEffect(() => {
        setOrigen(0);
        setDestino(0);
    }, [idaVuelta]);

    useEffect(() => {
        if (fechas && fechas.length > 1) {
            setFechaVuelta(new Date(fechas[1].year, fechas[1].monthIndex, fechas[1].day));
        }
        else {
            setFechaVuelta("");
        }
        if (fechas && fechas.length > 0) {
            setFechaIda(new Date(fechas[0].year, fechas[0].monthIndex, fechas[0].day));
        }
    }, [fechas])

    useEffect(() => {
        fetch(`https://api-cruce.tabsa.cl/api/origen`, { method: "GET" }).then(response => {
            if (response.ok) {
                response.json().then(data => {
                    setOrigenesIda(data.map(d => ({ id: d.id_origen, nombre: d.origen })));
                })
            }
            else {
                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`)
            }
        })

        fetch(`https://api-cruce.tabsa.cl/api/origen/ida-vuelta`, { method: "GET" }).then(response => {
            if (response.ok) {
                response.json().then(data => {
                    setOrigenesIdaVuelta(data.map(d => ({ id: d.id_origen, nombre: d.origen })));
                })
            }
            else {
                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`)
            }
        })

        fetch(`${process.env.REACT_APP_API_BASEURL}/carrousel/images`, { method: "GET" }).then(response => {
            if (response.ok) {
                response.json().then(data => {
                    setImages(data.data.images.map(d => d.url));
                })
            }
            else {
                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`)
            }
        })
        

    }, []);

    useEffect(() => {

        setDestinosIda([]);
        setDestinosIdaVuelta([]);

        fetch(`https://api-cruce.tabsa.cl/api/destino_tabsacl/${origen}`, { method: "GET" }).then(response => {
            if (response.ok) {
                response.json().then(data => {
                    setDestinosIda(data.map(d => ({ id: d.id_tramo, nombre: d.destino })));
                })
            }
            else {
                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`)
            }
        }).catch(error => {
            console.log(JSON.stringify(error));
        });

        fetch(`https://api-cruce.tabsa.cl/api/destino/ida-vuelta/${origen}`, { method: "GET" }).then(response => {
            if (response.ok) {
                response.json().then(data => {
                    setDestinosIdaVuelta(data.map(d => ({ id: d.id_tramo, nombre: d.destino })));
                })
            }
            else {
                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`)
            }
        }).catch(error => {
            console.log(JSON.stringify(error));
        });

    }, [origen]);


    useEffect(() => {
        console.log(`DESTINO: ${destino}`)
        if(destino == 42 || destino == 44 ||destino ==56){
            setCheckVehiculo(false)
            setAllowVehicle(true)
            console.log("DESACTIVAR VEHICULOS")
        }else{
            setAllowVehicle(false)
        }
    }, [destino]);

    return (
        <div className="slider-viajes">
            <Carousel onClick={() => {clickOut(false, false, false, false, false)}} nextIcon={<img src={NextIcon} />} prevIcon={<img src={PrevIcon} />}  >
                {
                    images.map((img, index) => {
                        return (
                            <Carousel.Item key={`${index}-item`} >
                                <img src={img} alt="slide" className="slider-image" />
                            </Carousel.Item>
                        )
                    })
                }
            </Carousel>
            <div className={`slider-top-shadow${!showCruce ? "-off": ""}`} onClick={() => {
                if (calendarFechaIda || calendarFechaVuelta) {
                    clickOut(false, false, false, false, false)
                }
            }}>
            <Navegacion clickOut={clickOut} rutas={rutas} showSelectLanguage={showSelectLanguage} showLogin={showLogin} />
        
            </div>
            <div className={`busqueda-viajes ${!showCruce ? "busqueda-viajes-off": ""}`} onClick={() => {
                if (calendarFechaIda || calendarFechaVuelta) {
                    //clickOut(false, false, false, false, false)
                }
            }}>
                <Row onClick={() => {clickOut(false, false, false, false, false)}}>
                    <Col lg={6} sm={6} xs={6}>
                        <Button 
                            className={`button-select-${!idaVuelta ? "active" : "inactive"}`}
                            onClick={() => {
                                setIdaVuelta(false);
                            }}>
                            {t("slider-viajes.solo-ida")}
                        </Button>
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                        <Button 
                            className={`button-select-${idaVuelta ? "active" : "inactive"}`}
                            onClick={() => {
                                setIdaVuelta(true);
                            }}>
                            {t("slider-viajes.ida-vuelta")}
                        </Button>
                    </Col>
                </Row>
                <Row onClick={() => {clickOut(false, false, false, false, false)}} style={{marginTop: 18}}>
                    <Col lg={6} sm={6} xs={6} className="form-col-padright">
                        <Form.Label className="busqueda-viajes-title" htmlFor="ddlOrigen">{t("slider-viajes.origen")}</Form.Label>
                        <Form.Select  className="form-field" id="ddlOrigen" aria-label={t("slider-viajes.origen")} placeholder={t("slider-viajes.origen-select")}
                            onChange={(evt) => {
                                setOrigen(evt.target.value);
                            }}
                        >
                            <option value={"0"} className="unselectable">{t("slider-viajes.origen-select")}</option>
                            {
                                !idaVuelta && origenesIda.map((origen, index) => {
                                    return (<option key={`${index}--origen--${origen}`} value={origen.id}>{origen.nombre}</option>)
                                })
                            }
                            {
                                idaVuelta && origenesIdaVuelta.map((origen, index) => {
                                    return (<option key={`${index}--origen--${origen}`} value={origen.id}>{origen.nombre}</option>)
                                })
                            }
                        </Form.Select>
                    </Col>
                    <Col lg={6} sm={6} xs={6} className="form-col-padleft">
                        <Form.Label className="busqueda-viajes-title" htmlFor="ddlDestino">{t("slider-viajes.destino")}</Form.Label>
                        <Form.Select className="form-field" id="ddlDestino" aria-label={t("slider-viajes.destino")} placeholder={t("slider-viajes.destino-select")}
                            onChange={(evt) => {
                                setDestino(evt.target.value);
                            }}
                        >
                            <option value={"0"} className="unselectable">{t("slider-viajes.destino-select")}</option>
                            {
                                !idaVuelta && destinosIda.map((destino, index) => {
                                    return (<option key={`${index}--destino--${destino}`} value={destino.id}>{destino.nombre}</option>)
                                })
                            }
                            {
                                idaVuelta && destinosIdaVuelta.map((destino, index) => {
                                    return (<option key={`${index}--destino--${destino}`} value={destino.id}>{destino.nombre}</option>)
                                })
                            }
                        </Form.Select>
                    </Col>
                </Row>
                        {
                            /*
                <Row style={{marginTop: 8}}>

                    <Col lg={12} sm={12} xs={12}>
                        <img alt="calendar" src={CalendarIcon} className="calendarIcon" 
                            onClick={() => { 
                                clickOut(false, !calendarFechaIda, false, false, false)
                            }} 
                        />
                        
                        <Form.Control 
                            className="form-field" 
                            type="text" 
                            id="txtFechaIda" 
                            onClick={() => { 
                                clickOut(false, !calendarFechaIda, false, false, false);
                            }} 
                            placeholder={t("slider-viajes.fecha-ida")}
                            readOnly 
                            value={ fechaIda ? Moment(fechaIda).format("DD-MM-yyyy") : ""} 
                        />
                            calendarFechaIda && false && 
                                <div className='floating-calendar'>
                                    <ReactCalendar
                                        minDate={new Date()}
                                        onChange={(date) => { 
                                            setFechaIda(date);
                                            calendarFechaIda = true;

                                            if (fechaVuelta && fechaVuelta !== "" && new Date(fechaVuelta) < new Date(date)) {
                                                setFechaVuelta(date)
                                            }
                                        }} 
                                        onActiveStartDateChange={() => {
                                               calendarFechaIda = false;
                                        }}
                                        value={fechaIda ? fechaIda : new Date()}
                                    />
                                </div>
                    </Col>
                </Row>
                                */
            } 
                        {
                            /*
                <Row style={{marginTop: 8}}>
                    <Col lg={12} sm={12} xs={12}>
                        <img alt="calendar" src={CalendarIcon} className="calendarIcon" 
                            onClick={() => { 
                                if (idaVuelta) {
                                    clickOut(false, false, !calendarFechaVuelta, false, false)
                                }
                                else {
                                    clickOut(false, false, false, false, false)
                                }
                            }} 
                        />
                        <Form.Control 
                            className="form-field" 
                            disabled={!idaVuelta} 
                            onClick={() => { 
                                if (idaVuelta) {
                                    clickOut(false, false, !calendarFechaVuelta, false, false)
                                }
                                else {
                                    clickOut(false, false, false, false, false)
                                }
                            }} 
                            type="text" 
                            id="txtFechaVuelta" 
                            placeholder={t("slider-viajes.fecha-vuelta")} 
                            readOnly 
                            value={ fechaVuelta ? Moment(fechaVuelta).format("DD-MM-yyyy") : ""} 
                        />
                            calendarFechaVuelta && false && 
                                <div className='floating-calendar'>
                                    <ReactCalendar
                                        minDate={new Date()}
                                        onChange={(date) => { 
                                            setFechaVuelta(date);
                                            calendarFechaVuelta = true;

                                            if (fechaIda && fechaIda !== "" && new Date(fechaIda) > new Date(date)) {
                                                setFechaIda(date)
                                            } 
                                        }} 
                                        onActiveStartDateChange={() => {
                                            calendarFechaVuelta = false;
                                        }}
                                        value={fechaVuelta ? fechaVuelta : new Date()} 
                                    />
                                </div>
                    </Col>
                </Row>
                            */
            }
                <Row>
                    <Col lg={idaVuelta ? 6: 12} md={idaVuelta ? 6: 12} sm={12} xs={12} className={idaVuelta ? "form-col-padright": ""}>
                        <p className="busqueda-viajes-title" style={{marginTop: 10}}>{t("slider-viajes.fecha-ida")}</p>
                        <div className="card-ida-vuelta" onClick={() => { clickOut(false, !calendarFechaIda, false, false, false) }}>
                            <p className="ida-vuelta-dates">
                                <img src={IdaIcon} height="20px" alt="" className="ida-vuelta-icons" />
                                { 
                                    fechas.length > 0 && 
                                        fechas[0].format("DD - MM - YYYY")
                                }
                            </p>
                        </div>
                    </Col>
                    {
                        idaVuelta &&
                        <Col lg={6} md={6} sm={12} xs={12} className="form-col-padleft">
                            <p className="busqueda-viajes-title" style={{marginTop: 10}}>{t("slider-viajes.fecha-vuelta")}</p>
                            <div className="card-ida-vuelta" onClick={() => { clickOut(false, false, !calendarFechaVuelta, false, false) }}>
                                <p className="ida-vuelta-dates">
                                    <img src={VueltaIcon} height="20px" alt="" className="ida-vuelta-icons" />
                                    { 
                                        fechas.length > 1 && 
                                            fechas[1].format("DD - MM - YYYY")
                                    }
                                </p>
                            </div>
                        </Col>
                    }
                </Row>
                {
                    (calendarFechaIda || calendarFechaVuelta) && 
                    <Row>
                        <Col lg={12}>
                            {
                                idaVuelta ?
                                <Calendar className="calendar-datepicker" value={fechas} minDate={new DateObject()}onChange={(values) => {
                                    setFechas(values);
                                    if(values.length > 1){
                                        clickOut(false,false,false,false,false)
                                    }
                                }} range/> :
                                <Calendar className="calendar-datepicker" value={fechas.length > 1 ? [fechas[0]] : fechas} minDate={new DateObject()} onChange={(values) => {
                                    setFechas(values.length > 1 ? [values[values.length - 1]] : values);
                                    clickOut(false,false,false,false,false)
                                }} />
                            }
                        </Col>
                    </Row>    
                }
                <Row onClick={() => {clickOut(false, false, false, false, false)}} style={{marginTop: 18}}>
                    <Col lg={12} sm={12} xs={12} style={{ textAlign: "left" }}>
                        <Form.Label className="busqueda-viajes-title" htmlFor="ddlPasajeros">
                            
                            {t("slider-viajes.pasajeros")}
                            
                            <span className="busqueda-viajes-title2">

                            </span>
                            
                        </Form.Label>
                        <Form.Select className="form-field" id="ddlPasajeros" aria-label={t("slider-viajes.pasajeros-select")} placeholder={t("slider-viajes.pasajeros-select")}
                            onChange={(evt) => setCantidadPasajeros(evt.target.value)} defaultValue={1}
                        >
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                        </Form.Select>
                    </Col>
                </Row>
                <Row onClick={() => {clickOut(false, false, false, false, false)}} style={{marginTop: 18}}>
                    <Col lg={12} sm={12} xs={12} style={{ textAlign: "left" }}>
                        <Form.Label className="busqueda-viajes-title">{t("slider-viajes.vehiculos")}</Form.Label>
                        <p className="busqueda-viajes-subtitle">{t("slider-viajes.vehiculos-detalle")}</p>
                    </Col>
                </Row>
                <Row onClick={() => {clickOut(false, false, false, false, false)}}>
                    <Col lg={4} sm={4} xs={4}>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            className={`check-vehiculo check-vehiculo-${checkVehiculo ? "checked" : "unchecked"}`}
                            label={checkVehiculo ? t("slider-viajes.vehiculos-check-si") : t("slider-viajes.vehiculos-check-no")}
                            checked={checkVehiculo}
                            onChange={(evt) => {
                                setCheckVehiculo(evt.target.checked);
                            }}
                            disabled={allowVehicle} 
                        />
                    </Col>
                    <Col lg={8} sm={8} xs={8}>
                        <Form.Select className="form-field" id="ddlVehiculoCategoria" disabled={!checkVehiculo} aria-label={t("slider-viajes.vehiculos-categoria")} placeholder={t("slider-viajes.vehiculos-categoria")}
                            onChange={(evt) => setCategoriaVehiculo(evt.target.value)}
                        >
                            <option value={0} className="unselectable">{t("slider-viajes.vehiculos-categoria")}</option>
                            <option value={1}>{t("slider-viajes.vehiculos-categoria-1")}</option>
                            <option value={7}>{t("slider-viajes.vehiculos-categoria-2")}</option>
                            <option value={3}>{t("slider-viajes.vehiculos-categoria-3")}</option>
                            <option value={2}>{t("slider-viajes.vehiculos-categoria-4")}</option>
                            <option value={8}>{t("slider-viajes.vehiculos-categoria-5")}</option>
                            <option value={6}>{t("slider-viajes.vehiculos-categoria-6")}</option>
                            <option value={18}>{t("slider-viajes.vehiculos-categoria-7")}</option>
                            <option value={17}>{t("slider-viajes.vehiculos-categoria-8")}</option>
                        </Form.Select>
                    </Col>
                </Row>
                <Row onClick={() => {clickOut(false, false, false, false, false)}} style={{marginTop: 18}}>
                    <Col lg={12}>
                        <Button className="button-confirm" style={{ width: "100%" }} onClick={() => {

                            const data = new FormData();
                            data.append("sitio_prueba", 0);
                            data.append("url_externo", "/portal/index.php/es/reservas-ext2/es/component/r2/?view=externa&task=externa.form_externa");
                            data.append("origen", origen);
                            data.append("destino", destino);
                            data.append("fecha_salida", fechaIda);
                            data.append("cantidad_pasajeros", cantidadPasajeros);

                            if (idaVuelta) {
                                data.append("fecha_regreso", fechaVuelta);
                            }
                            if (checkVehiculo) {
                                data.append("tipo_vehiculo", categoriaVehiculo);
                            }


                            let fechaIda_format = new Date(fechaIda)
                            let ida_day = fechaIda_format.getDate() < 10? "0"+fechaIda_format.getDate() : fechaIda_format.getDate() ;
                            let ida_month = (fechaIda_format.getMonth()+1) <10? "0"+(fechaIda_format.getMonth()+1) : (fechaIda_format.getMonth()+1) ;
                            let ida_year = fechaIda_format.getFullYear();

                            let fechaVuelta_format = new Date(fechaVuelta)
                            let vuelta_day = fechaVuelta_format.getDate() < 10? "0"+fechaVuelta_format.getDate() : fechaVuelta_format.getDate() ;
                            let vuelta_month = (fechaVuelta_format.getMonth()+1) <10? "0"+(fechaVuelta_format.getMonth()+1) : (fechaVuelta_format.getMonth()+1) ;
                            let vuelta_year = fechaVuelta_format.getFullYear();
                            let error = false; 
                            let error_message = ""; 

                            /* VALIDACIONES */
                            if(cantidadPasajeros == 0){
                                error = true;
                                error_message += "Debe escoger la cantidad de pasajeros.    "
                            }
                            if(idaVuelta){
                                if(fechaVuelta == ""){
                                    error = true;
                                    error_message += "Debe escoger la fecha de regreso.     "
                                }
                            }
                            console.log(origen)
                            console.log(destino)
                            if(origen == 0 || destino == 0){
                                error = true;
                                error_message += "Debe escoger origen y destino.    "
                            }
                            

                            if(error){
                                Swal.fire({
                                    icon: "error",
                                    title: "Error en formulario",
                                    text: error_message
                                })
                            }else{ //SIN ERRORES
                                if(destino && fechaIda){
                                    if((destino == 1 || destino == 2) || (destino >= 41 && destino <= 56)){ //Porvenir IDA, IDA Y VUELTA, NATALES IDA e IDA Y VUELTA
                                        let fechaidaFormateada = ida_year+"-"+ida_month+"-"+ida_day;
                                        let fechavueltaFormateada = 0
                                        if (idaVuelta) {
                                            fechavueltaFormateada = vuelta_year+"-"+vuelta_month+"-"+vuelta_day;
                                        }
                                        let url = ""
                                        if(checkVehiculo){
                                            url = `https://reservas.tabsa.cl/cruces_externo/${destino}/${fechaidaFormateada}/${cantidadPasajeros}/${categoriaVehiculo}/${fechavueltaFormateada}`
                                            // url = `https://reservas.tabsa.cl/cruces/${destino}/${fechaidaFormateada}/${fechavueltaFormateada}/${cantidadPasajeros}/${categoriaVehiculo}`
                                        }else{
                                            url = `https://reservas.tabsa.cl/cruces_externo/${destino}/${fechaidaFormateada}/${cantidadPasajeros}/0/${fechavueltaFormateada}`
                                        }
                                        window.open(url, '_blank');
        
                                    }else{
                                        let fechaFormateada = ida_day+"-"+ida_month+"-"+ida_year;
                                        let url = ""
                                        if(checkVehiculo){
                                            url = `https://intranet.tabsa.cl/portal/index.php/es/?option=com_r2&view=externa&task=externa.form_externa&fecha_salida=${fechaFormateada}&destino=${destino}&cantidad_pasajeros=${cantidadPasajeros}}&tipo_vehiculo=${categoriaVehiculo}`
                                        }else{
                                            url = `https://intranet.tabsa.cl/portal/index.php/es/?option=com_r2&view=externa&task=externa.form_externa&fecha_salida=${fechaFormateada}&destino=${destino}&cantidad_pasajeros=${cantidadPasajeros}`
                                        }
                                        window.open(url, '_blank');
                                    }
                                }
                            }


                        }}>{t("slider-viajes.buscar")}</Button>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default SliderViajes;
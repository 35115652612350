import "./Banners.css";
import { Row, Col } from "react-bootstrap";
import BannerCelular from "./src/celular-banner.svg";
import BannerFlecha from "./src/icono-flecha-banner.svg";
import CheckIcono from "./src/check.svg";
import GoogleIcono from "./src/google-play.png";
import AppStoreIcono from "./src/app-store.png";

import { useTranslation } from "react-i18next";

function Banners({ clickOut }) {
    const [t, i18n] = useTranslation("global");

    return (
        <>
            <Row className="banner-descarga-row" onClick={() => { clickOut(false, false, false, false, false) }}>
                <Col className="no-margin" lg={2} md={3} xs={3}>
                    <img className="img-banner-flecha" src={BannerFlecha} />
                </Col>
                <Col className="no-margin" lg={3} md={5} xs={9}>
                    <Row className="row-banner-margin-top">
                        <Col lg={12} sm={12} xs={12}>
                            <p className="banner-descarga">{t("banners.descarga")} <br className="d-none d-sm-block" />{t("banners.nuestra")} <span className="banner-descarga-app">{t("banners.app")}</span></p>
                        </Col>
                    </Row>
                    <Row className="row-banner-app-marg">
                        <Col lg={5} sm={5} xs={5}>
                            <img className="img-google-play" alt="Google play" src={GoogleIcono} onClick={() => { window.open("https://play.google.com/store/apps/details?id=cl.tabsaApp.app&hl=es_CL&gl=US", "_blank") }} />
                        </Col>
                        <Col lg={7} sm={7} xs={7}>
                            <img className="img-app-store" alt="App store" src={AppStoreIcono} onClick={() => { window.open("https://apps.apple.com/us/app/tabsa-app/id1517113811", "_blank") }} />
                        </Col>
                    </Row>
                </Col>
                <Col className="no-margin d-none d-lg-block" lg={4} md={0} xs={0}>
                    <Row className="check-row row-banner-margin-top">
                        <Col lg={1} md={1} xs={1}>
                            <img className="check-icon-banner" alt="check" src={CheckIcono} />
                        </Col>
                        <Col lg={11} md={11} xs={11}>
                            <p className="check-text">{t("banners.verifica-estados")}</p>
                        </Col>
                    </Row>
                    <Row className="check-row">
                        <Col lg={1} md={1} xs={1}>
                            <img className="check-icon-banner" alt="check" src={CheckIcono} />
                        </Col>
                        <Col lg={11} md={11} xs={11}>
                            <p className="check-text">{t("banners.descarga-tarjeta")}</p>
                        </Col>
                    </Row>
                    <Row className="check-row">
                        <Col lg={1} md={1} xs={1}>
                            <img className="check-icon-banner" alt="check" src={CheckIcono} />
                        </Col>
                        <Col lg={11} md={11} xs={11}>
                            <p className="check-text">{t("banners.informate")}</p>
                        </Col>
                    </Row>
                </Col>
                <Col className="no-margin d-none d-md-block" lg={3} md={4} xs={0}>
                    <img className="img-banner-celular" src={BannerCelular} />
                </Col>
            </Row>
        </>
    );
}

export default Banners;
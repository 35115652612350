import "./AdminModals.css";
import { useEffect, useState } from "react";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import Loading from "../../components/loading/Loading";
import AdminNavbar from "../../components/navbar/AdminNavbar";
import CloseButton from "./src/close-circle.svg";

function AdminModals() {
    const [usuario, setUsuario] = useState(sessionStorage.getItem("admin-user"));

    if (!usuario || usuario === "") {
        window.location.href = "/admincms/login";
    }

    const [showModal, setShowModal] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const [modal, setModal] = useState({ active: false });

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASEURL}/modals/modals`, { method: "GET" }).then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    setModal(data.data);
                    setShowLoading(false);
                })
            }
            else {
                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
            }
        })
    }, [])

    return (
        <>
            <AdminNavbar />
            <Loading show={showLoading} />
            <Container>
                <Row className="justify-content-center content-admin-carrousel">
                    <Col lg={11}>
                        <Row className="justify-content-center">
                            <Col lg={8}>
                                <p className="p-titulo-admin">Modal</p>
                            </Col>
                            <Col lg={3}>
                                <Button className="btn-admin" disabled={showLoading} onClick={() => {

                                    setShowLoading(true);

                                    try {
                                        fetch(`${process.env.REACT_APP_API_BASEURL}/modals/modals`, {
                                            method: "POST",
                                            headers: {
                                                "Content-Type": "application/json"
                                            },
                                            body: JSON.stringify({ ...modal, ultimoUsuario: usuario })
                                        }).then((responseL) => {
                                            if (responseL.ok) {
                                                Swal.fire({
                                                    icon: "success",
                                                    title: "Guardado correctamente",
                                                    text: "Puedes visualizar el modal en el sitio",
                                                    footer: "<a href='/' target='_blank'>tabsa.cl</a>"
                                                })
                                                .then((alResponse) => {
                                                    setShowLoading(false);
                                                })
                                            }
                                            else {
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "No se pudo guardar",
                                                    text: "Favor contacta con el equipo de desarrollo."
                                                })
                                                .then((alResponse) => {
                                                    setShowLoading(false);
                                                })
                                            }
                                        })
                                    }
                                    catch (error) {
                                        Swal.fire({
                                            icon: "error",
                                            title: "No se pudo guardar",
                                            text: `Favor contacta con el equipo de desarrollo. ${error}`
                                        })
                                        .then((alResponse) => {
                                            setShowLoading(false);
                                        })
                                    }
                                    
                                }}>Guardar</Button>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col lg={5}>
                                <Row>
                                    <Col>
                                        <Form.Label className="label-admin" htmlFor="txtFileL">Imagen del modal</Form.Label>
                                        <Form.Control 
                                            className={`form-field-admin`} 
                                            type="file" 
                                            id="txtFileL"
                                            placeholder="Sube la imagen del banner izquierdo aquí."
                                            accept="image/jpeg"
                                            onChange={(evt) => {
                                                if (evt.target.files.length > 0) {

                                                    setShowLoading(true);

                                                    let formData = new FormData();

                                                    formData.append("pdf", evt.target.files[0]);
                                                    
                                                    fetch('https://recursos.tabsa.cl/api/file/save', {
                                                        method: "POST",
                                                        body: formData
                                                    })
                                                    .then(response => {
                                                        if (response.ok) {
                                                            response.json().then(data => {
                                                               
                                                                setModal({ img: data.url, active: modal.active });
                                                                setShowLoading(false);
                                                            })
                                                        }
                                                        else {
                                                            console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                            setShowLoading(false);
                                                        }
                                                    })
                                                    .catch(error => {
                                                        console.log(error)
                                                    })

                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Check 
                                            type="checkbox"
                                            className={`form-check-login-admin`}
                                            id="chkActivo"
                                            value={modal.active} 
                                            label={"Activo"}
                                            onChange={(evt) => setModal({ ...modal, active: evt.target.checked })}
                                        />
                                        <Form.Label className="label-admin" htmlFor="chkActivo">Está opción habilitará el modal en el sitio</Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={4}>
                                        <Button className="btn-admin" onClick={() => {
                                            setShowModal(true);
                                        }}>Previsualizar</Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={1}></Col>
                            <Col lg={5}>
                                <p className="label-admin">Imagen actual</p>
                                <img className="img-admin" src={modal.img} alt="img-admin" />
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col lg={11}>
                                {
                                    showModal &&
                                    <div className="div-modal-notf">
                                        <img className="img-modal-notf" src={modal.img} alt="mdlntf" />
                                        <img className="cb-modal-notf img-clickable" src={CloseButton} alt="mdlntfcb" onClick={() => setShowModal(false)} />
                                    </div>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default AdminModals;